<template>
  <div>
    <HeaderContent />
    <div class="container"><router-view/></div>
    <FooterContent />
  </div>
</template>

<script>
  import HeaderContent from './components/HeaderContent';
  import FooterContent from './components/FooterContent';

  export default {
    name: 'App',
    components: {
    HeaderContent,
    FooterContent
}
  }
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
