<template>
  <div class="container">
    <h1>Buckets</h1>
    <div v-if="!loading && data && data.length">
      <table class="table table-striped table-sm">
        <thead class="table-dark">
          <tr>
            <th class="text-start" scope="col">Name</th>
            <th class="text-start" scope="col">Environment</th>
            <th class="text-start" scope="col">Type</th>
            <th class="text-start" scope="col">Account</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bucket of data" :key="bucket.name">
            <td class="text-start"><router-link :to="{name: 'bucket', params: {bucket_name: bucket.name} }">{{bucket.name}}</router-link></td>
            <td class="text-start">{{bucket.environment}}</td>
            <td class="text-start">{{bucket.data_type}}</td>
            <td class="text-start">{{bucket.aws_account_number}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <LoadingSpinner v-if="loading" />

    <p v-if="error">


    </p>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from 'axios'
import LoadingSpinner from '../views/LoadingSpinner.vue'
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: 'BucketList',
  props: {},
  setup() {
    const data = ref(null);
    const loading = ref(null);
    const error = ref(null);

    const { getAccessTokenSilently } = useAuth0();

    async function fetchBuckets(){
      loading.value = true
      const token = await getAccessTokenSilently();
      console.log(token)
      axios.get('/buckets/', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(
        function(response){
          loading.value = false;
          data.value = response.data;
          console.log(response.data);
          console.log(response.status);
          console.log(response.statusText);
          console.log(response.headers);
          console.log(response.config);
        }
      );
    }

    onMounted(() => { fetchBuckets(); });

    return {
      data,
      loading,
      error
    }
  },
  components: { LoadingSpinner }
}
</script>