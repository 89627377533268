import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BucketList from '../components/BucketList.vue'
import BucketDetail from '../components/BucketDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/buckets',
    name: 'buckets',
    component: BucketList
  },
  {
    path: '/buckets/:bucket_name',
    name: 'bucket',
    component: BucketDetail,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
