<template>
  <div class="container">
    <header class="d-flex flex-wrap justify-content-center py-3 mv-4 border-bottom">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img :src="home_logo" />
      </a>
      <b-nav>
        <b-nav-item><router-link to="/" class="nav-link" active-class="nav-link active">Home</router-link></b-nav-item>
        <b-nav-item-dropdown id="buckets-dropdown" text="Buckets" class="nav-link" toggle-class="nav-link-custom">
          <b-dropdown-item><router-link to="/buckets" class="nav-link">List</router-link></b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="isAuthenticated"><a class="nav-link" @click="logout">Log Out</a></b-nav-item>
        <b-nav-item v-else><a class="nav-link" @click="login">Log In</a></b-nav-item>
      </b-nav>
    </header>
  </div>
</template>

<script>

import home_logo from "../assets/img/desktop-logo.png"
import { useAuth0 } from '@auth0/auth0-vue'

export default {
  setup(){
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    return {
      name: 'HeaderContent',
      home_logo,
      isAuthenticated,
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({ returnTo: window.location.origin });
      }
    }
  }
}

</script>
