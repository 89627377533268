import { createApp } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import axios from 'axios'

import App from './App.vue'
import router from './router'

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// Axios Configuration
axios.defaults.baseURL = 'https://wld-management-api.ah-data.dev.hbu.co'

// App Spin Up
const my_app = createApp(App)

// Plugins
my_app
.use(BootstrapVue3)
.use(router)
.use(createAuth0({
  domain: "bamboohealth-dev-internal.us.auth0.com",
  client_id: "uyWKYBznHBWjOLaSjvc1ZTnhxlsGk6Gh",
  redirect_uri: window.location.origin,
  audience: axios.defaults.baseURL + '/' // Axios doesn't want the ending "/".  Auth0 does.
}))

my_app.provide('$axios', axios)

my_app.mount('#app')
