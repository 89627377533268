<template>
  <footer class="fixed-bottom d-flex flex-wrap justify-content-center py-2 px-2 border-top bg-white">
    <p class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
      &copy;Bamboo Health 2022 - All Rights Reserved
    </p>
    <p v-if="isAuthenticated" class="mb-3 mb-md-0 text-dark text-decoration-none">
      {{user.name}}
    </p>
  </footer>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue'

export default {
  setup(){
    const { isAuthenticated, user } = useAuth0();
    return {
      name: 'FooterContent',
      user,
      isAuthenticated
    }
  }
}
</script>
