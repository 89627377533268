<template>
  <div class="home-content">
    <h1>Bamboo Health Data Lake Management</h1>
    <p>"If you have built castles in the air, your work need not be lost; that is where they should be. Now put the foundations under them."<br/>- Henry David Thoreau, Walden</p>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
