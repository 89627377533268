<template>
  <div class="container">
    <h1>{{bucket_name}}</h1>
    <div v-if="!loading && bucket">
      <h3>General Information</h3>
      <table class="table table-striped table-sm">
        <tbody>
          <tr>
            <th scope="row" class="text-start">Data Type</th>
            <td class="text-start">{{bucket.data_type}}</td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Environment</th>
            <td class="text-start">{{bucket.environment}}</td>
          </tr>
          <tr>
            <th scope="row" class="text-start">AWS Account</th>
            <td class="text-start">{{bucket.aws_account_number}}</td>
          </tr>
          <tr>
            <th scope="row" class="text-start">Encryption Key ARN</th>
            <td class="text-start">{{bucket.encryption_key}}</td>
          </tr>
        </tbody>
      </table>
      <h3>Tags</h3>
      <table class="table table-striped table-sm">
        <thead class="table-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tagValue, name) of bucket.tags" :key="name">
            <th scope="row" class="text-start">{{name}}</th>
            <td class="text-start">{{tagValue}}</td>
          </tr>
        </tbody>
      </table>
      <div v-for="(policyRoles, policyName) of bucket.policy" :key="policyName" class="container">
        <div class="row align-items-start"><h5>{{policyName}} Roles</h5></div>
        <div class="row align-items-start">
          <ul v-if="policyRoles.length && policyRoles.length > 0" class="list-group">
            <li v-for="r in policyRoles" :key="r" class="list-group-item text-start">{{r}}</li>
          </ul>
          <p v-else>No Roles Defined</p>
        </div>
      </div>
    </div>

    <LoadingSpinner v-if="loading" />
  </div>
</template>

<script>

import axios from 'axios'
import {ref, onMounted} from 'vue'
import LoadingSpinner from '../views/LoadingSpinner.vue'
import { useAuth0 } from '@auth0/auth0-vue'

export default {
    name: "BucketDetail",
    props: ["bucket_name"],
    setup(props) {
        const bucket = ref(null);
        const loading = ref(null);
        const error = ref(null);

        const { getAccessTokenSilently } = useAuth0();

        async function fetchBucket(bucket_name) {
          if (bucket_name == null) {
              console.warn("!!! bucket_name is null !!!");
              return;
          }
          loading.value = true;
          const token = await getAccessTokenSilently();
          console.debug("fetching: /buckets/" + bucket_name);
          axios.get("/buckets/" + bucket_name, {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (response) {
              loading.value = false;
              bucket.value = response.data;
              console.log(response.data);
              console.log(response.status);
              console.log(response.statusText);
              console.log(response.headers);
              console.log(response.config);
          });
        }
        onMounted(() => { fetchBucket(props.bucket_name); });
        return {
            bucket,
            loading,
            error
        };
    },
    components: { LoadingSpinner }
}
</script>
